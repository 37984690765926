import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import BreadCrumbs from 'components/Layout/BreadCrumbs';
import SEOHelmet from 'components/Layout/SEOHelmet';
export const _frontmatter = {
  "title": "Alarm Input",
  "path": "/Frequently_Asked_Question/Alarm_Input/",
  "dateChanged": "2018-03-15",
  "author": "Mike Polinowski",
  "excerpt": "How the alarm input works.",
  "image": "../FAQ_SearchThumb.png",
  "social": "/images/Search/FAQ_SearchThumb.webp",
  "toc": "/images/Search/TOC_Icons/Wiki_Tiles_FAQs_white.webp",
  "chapter": "FAQs",
  "faq": "HD Cameras"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <SEOHelmet title='Alarm Input' dateChanged='2018-03-15' author='Mike Polinowski' tag='INSTAR IP Camera' description='How the alarm input works.' image='/images/Search/FAQ_SearchThumb.png' twitter='/images/Search/FAQ_SearchThumb.webp' location={props.location.pathname} mdxType="SEOHelmet" />
    <BreadCrumbs locationBC={props.location} locationEN={props.location.pathname} locationDE='/de/Frequently_Asked_Question/Alarm_Input/' locationFR='/fr/Frequently_Asked_Question/Alarm_Input/' crumbLabel="FAQs" mdxType="BreadCrumbs" />
    
    <h1 {...{
      "id": "alarm-input",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#alarm-input",
        "aria-label": "alarm input permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Alarm Input`}</h1>
    <p><strong parentName="p">{`Q`}</strong>{`: How the alarm input works.`}</p>
    <p><strong parentName="p">{`A`}</strong>{`: I need a short information on how the input / input of the camera must be connected. In the manual the input / input is described with + and - (see attachment). How is this information to be interpreted? Does this mean that a pot. Free contact of an external relay must be connected to the marked connection points?`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/d0b9a915c6295147fd743d2b7d3eafe9/67fe0/IN-Motion500_12.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "41.30434782608695%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAICAIAAAB2/0i6AAAACXBIWXMAAAsTAAALEwEAmpwYAAABv0lEQVQY02P4/////ddfJp18+u//v//////79/f///+fXzy5OLXr/PK5n759BguCpP7//3/p4bX9N45eenL10Zsn////Z/j////1R7cWHdv++PWj//////7z5////7svHhPyNhCMtl5/6TBI8O+fv3///v//z6sxyqojxKDUqWxBPVTz60+v9tze/ujTg//////5C9J84P416QnZcn05O6+fBQv+hdi8+NCaio1d9au77ry4B9X86dSJjdMbLm1Y9PfsOYiiP3///vr39x/YwT/A4M2bN+/evfv44fPbN+8+vPvw+cOnr1+/gjS/XL06zdWrM7fo05q1/8GW/Pz58/69e4cPHlw0f/69O3eWL1+uoiBnaKAX6WUR6mWmbawrISnW3dUJ0vzz9u3elIxpOQVfLl2C2Lxt6xYtDQ1xEZGDBw78//9/4YIFGqrKrk52QW624aGuiQVJltZm06dOZfgDDqHmts6wiLjff/7+BwfsqcP7/VwdvD09//////fv358/f378CHLn12/fX7150zSzYcvhzSA/g4Px/7Ejxyb0TIDHyvdvX8+cPHH92jXkeIIwvn3/NmP9tMMXQbEAAD4keDNdbHOvAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/d0b9a915c6295147fd743d2b7d3eafe9/e4706/IN-Motion500_12.avif 230w", "/en/static/d0b9a915c6295147fd743d2b7d3eafe9/d1af7/IN-Motion500_12.avif 460w", "/en/static/d0b9a915c6295147fd743d2b7d3eafe9/7f308/IN-Motion500_12.avif 920w", "/en/static/d0b9a915c6295147fd743d2b7d3eafe9/4293a/IN-Motion500_12.avif 1101w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/d0b9a915c6295147fd743d2b7d3eafe9/a0b58/IN-Motion500_12.webp 230w", "/en/static/d0b9a915c6295147fd743d2b7d3eafe9/bc10c/IN-Motion500_12.webp 460w", "/en/static/d0b9a915c6295147fd743d2b7d3eafe9/966d8/IN-Motion500_12.webp 920w", "/en/static/d0b9a915c6295147fd743d2b7d3eafe9/41823/IN-Motion500_12.webp 1101w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/d0b9a915c6295147fd743d2b7d3eafe9/81c8e/IN-Motion500_12.png 230w", "/en/static/d0b9a915c6295147fd743d2b7d3eafe9/08a84/IN-Motion500_12.png 460w", "/en/static/d0b9a915c6295147fd743d2b7d3eafe9/c0255/IN-Motion500_12.png 920w", "/en/static/d0b9a915c6295147fd743d2b7d3eafe9/67fe0/IN-Motion500_12.png 1101w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/d0b9a915c6295147fd743d2b7d3eafe9/c0255/IN-Motion500_12.png",
              "alt": "Alarm I/O",
              "title": "Alarm I/O",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p><a parentName="p" {...{
        "href": "/en/Products/IN-Motion/500/"
      }}>{`IN-Motion 500`}</a></p>
    <p>{`Information about the alarm I / O can be found in our online documentation:`}</p>
    <p><a parentName="p" {...{
        "href": "/en/Indoor_Cameras/IN-8015_HD/Technical_Specifications/"
      }}>{`IN-8015 FHD Technische Spezifikationen`}</a></p>
    <p><a parentName="p" {...{
        "href": "/en/Outdoor_Cameras/IN-7011_HD/Technical_Specifications/"
      }}>{`IN-7011 HD Technische Spezifikationen`}</a></p>
    <p><a parentName="p" {...{
        "href": "/en/Outdoor_Cameras/IN-5907_HD/Technical_Specifications/"
      }}>{`IN-5907 HD Technische Spezifikationen`}</a></p>
    <p><a parentName="p" {...{
        "href": "/en/Outdoor_Cameras/IN-5905_HD/Technical_Specifications/"
      }}>{`IN-5905 HD Technische Spezifikationen`}</a></p>
    <p>{`A terminal, potential-free, must be connected to the alarm input, which opens or closes a relay in the event of an alarm (N.C. or N.O.). You can also test the functionality very easily by bridging the two contacts with a wire. When you enter the input on N.C. You should receive a permanent alarm as soon as you remove the wire.`}</p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/en/FAQs/"
        }}>{`FAQs`}</a>
        <ul parentName="li">
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-9420WQHD/"
            }}>{`IN-9420 WQHD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-9408WQHD/"
            }}>{`IN-9408 WQHD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-8415WQHD/"
            }}>{`IN-8415 WQHD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-8403WQHD/"
            }}>{`IN-8403 WQHD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-8401WQHD/"
            }}>{`IN-8401 WQHD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-9020FHD/"
            }}>{`IN-9020 Full HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-9010FHD/"
            }}>{`IN-9010 Full HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-9008FHD/"
            }}>{`IN-9008 Full HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-8015FHD/"
            }}>{`IN-8015 Full HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-8003FHD/"
            }}>{`IN-8003 Full HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-8001FHD/"
            }}>{`IN-8001 Full HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-7011HD/"
            }}>{`IN-7011 HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-6014HD/"
            }}>{`IN-6014 HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-6012HD/"
            }}>{`IN-6012 HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-6001HD/"
            }}>{`IN-6001 HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-5907HD/"
            }}>{`IN-5907 HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-5905HD/"
            }}>{`IN-5905 HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/VGA_Series/"
            }}>{`VGA Series`}</a></li>
        </ul>
      </li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      